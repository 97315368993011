.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.end-section {
  margin-bottom: 50px !important;
}

@media (min-width: 992px) {
  #topnav .navigation-menu.nav-left {
    justify-content: flex-end !important;
  }
}

@media (max-width: 650px) {
  .center-items {
    display: flex !important;
    justify-content: center !important;
  }
}

@media (max-width: 480px) {
  .card.blog img {
    height: 150px !important;
    width: 100% !important;
    object-fit: cover;
  }
}

.img-fixed {
  height: 35vh !important;
  width: 100% !important;
}

.page-link {
  align-items: center;
  border: 1px solid #f1f5f9;
  color: #94a3b8;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.center-btn {
  display: flex;
  justify-content: center;
}

.text-muted {
  color: #526074 !important;
}

.less-padding-section {
  padding: 50px 0;
  position: relative;
}

.loan-calculator {
  font-family: "Roboto", sans-serif;
  width: 600px;
  margin: 24px auto;
  background: #fff;
  box-shadow: 0 12px 50px -11px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  color: #14213d;
  overflow: hidden;
}

.loan-calculator,
.loan-calculator * {
  box-sizing: border-box;
}

.loan-calculator .top {
  background: #14213d;
  color: #fff;
  padding: 32px;
}

.loan-calculator .top h2 {
  margin-top: 0;
}

.loan-calculator form {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.loan-calculator .title {
  margin-bottom: 16px;
}

.loan-calculator form input {
  font-size: 20px;
  padding: 8px 24px;
  width: 100%;
}

.loan-calculator .result {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loan-calculator .result .left {
  width: 100%;
  padding: 8px 32px;
}

.loan-calculator .left h3 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}

.loan-calculator .result .value {
  font-size: 30px;
  font-weight: 900;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(20, 33, 61, 0.2);
}

.loan-calculator .result .value::before {
  content: "$";
  font-size: 24px;
  font-weight: 400;
  margin-right: 6px;
  opacity: 0.4;
}

.loan-calculator .calculate-btn {
  background: #e63946;
  color: #fff;
  border: none;
  padding: 8px 32px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 900;
  cursor: pointer;
  margin: 24px 0;
}

.loan-calculator .right {
  width: 50%;
}

@media (max-width: 650px) {
  .loan-calculator {
    width: 90%;
    max-width: 500px;
  }

  .loan-calculator form {
    flex-direction: column;
    gap: 20px;
  }

  .loan-calculator .result {
    flex-direction: column;
    text-align: center;
  }
}

.inputForm {
  border-color: lightgray;
  padding: 10px;
}

.messageBoxBorder {
  border-color: lightgray;
}
